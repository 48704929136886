import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import CardCalon from "../../components/CardCalon/CardCalon";
import instance from "../../services/axios";

export default function Project() {
  const { projectSlug } = useParams();
  const detik = 15;
  const [dataDefault, setDataDefault] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [medsos, setMedsos] = useState("article");

  useEffect(() => {
    setIsLoading(true);
    const fetchDataDefault = async () => {
      const dataDefault = await instance.get(`api/v1/${projectSlug}`);
      setDataDefault(dataDefault.data);
      setIsLoading(false);
    };
    fetchDataDefault();
    const timer = setInterval(() => {
      setIsLoading(true);
      fetchDataDefault();
    }, detik * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [setIsLoading, projectSlug]);

  return (
    <div>
      <div className="wrapper-person">
        {dataDefault.length > 0 ? (
          dataDefault.map((data, idx) => (
            <CardCalon
              key={idx}
              isLoading={isLoading}
              setMedsos={setMedsos}
              medsos={medsos}
              dataCalon={data?.user_data}
              image={`data:image/jpeg;base64, ${data?.profile_link}`}
              nama={data?.profile_title}
            />
          ))
        ) : (
          <div className="loading-circle">
            <CircularProgress size={100} />
          </div>
        )}
      </div>
    </div>
  );
}
