import { Close } from "@mui/icons-material";
import React, { useRef } from "react";
import swal from "sweetalert";

import AuthService from "../../services/auth.services";
import useStore from "../../store";
import instance from "../../services/axios";
import styles from "./Modal.module.css";
import { PrimaryButton } from "../Button/Button";

function Modal() {
  const setModal = useStore((state) => state.setIsModal);

  const namaRef = useRef();
  const titleRef = useRef();
  const currentUser = AuthService.getCurrentUser();

  const handlePostCalon = async (e) => {
    e.preventDefault();
    const nama = namaRef.current.value;
    const title = titleRef.current.value;
    const formData = new FormData();
    formData.append("name", nama);
    formData.append("title", title);
    try {
      const dataReturnPost = await instance.post(
        "api/v3/usermonitor/set",
        formData,
        {
          headers: {
            Authorization: `Bearer ${currentUser.success.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setModal();
      swal({
        text: dataReturnPost.data?.msg,
        icon: "success",
        timer: 5000,
        buttons: false,
      });
    } catch (error) {
      console.error("Error posting data:", error);
      swal({
        text: "Terjadi kesalahan saat menyimpan data.",
        icon: "error",
        timer: 5000,
        buttons: false,
      });
    }
  };

  return (
    <div className={styles.modal_container}>
      <div className={styles.container_form}>
        <div className={styles.icon_close_modal} onClick={() => setModal()}>
          <Close />
        </div>
        <div>
          <form onSubmit={handlePostCalon}>
            <label htmlFor="title-calon">Project Title</label>
            <input
              type="text"
              id="title-calon"
              className={styles.input_calon}
              ref={titleRef}
              required
            />
            <label htmlFor="nama-calon">Keyword</label>
            <textarea
              id="nama-calon"
              className={styles.textarea_custom}
              ref={namaRef}
              required
            />
            <div className={styles.btn_cari_container}>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Modal;
