import { Close } from "@mui/icons-material";
import React, { useRef } from "react";
import swal from "sweetalert";
import AuthService from "../../services/auth.services";
import useStore from "../../store";
import instance from "../../services/axios";

function ModalEdit() {
  const setModal = useStore((state) => state.setIsModalEdit);
  const modal = useStore((state) => state.isModalEdit);

  const namaRef = useRef();
  const titleRef = useRef();
  const currentUser = AuthService.getCurrentUser();

  const handleEdit = async (e) => {
    e.preventDefault();
    const nama = namaRef.current.value;
    const title = titleRef.current.value;
    const formData = new FormData();
    formData.append("name", nama);
    formData.append("title", title);
    try {
      const dataReturnPost = await instance.post(
        `api/v3/usermonitor/update/${modal.profilId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${currentUser.success.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setModal(null);
      swal({
        text: dataReturnPost.data?.msg,
        icon: "success",
        timer: 5000,
        buttons: false,
      });
    } catch (error) {
      console.error("Error updating data:", error);
      swal({
        text: "Terjadi kesalahan saat memperbarui data.",
        icon: "error",
        timer: 5000,
        buttons: false,
      });
    }
  };

  return (
    <div className="modal-container">
      <div className="container-form">
        <div className="icon-close-modal" onClick={() => setModal(null)}>
          <Close />
        </div>
        <form onSubmit={handleEdit}>
          <label htmlFor="title-calon">Project Title</label>
          <input
            type="text"
            id="title-calon"
            className="input-calon"
            ref={titleRef}
            required
            defaultValue={modal.nama}
          />
          <label htmlFor="nama-calon">Keyword</label>
          <textarea
            id="nama-calon"
            className="text-area-pencarian"
            ref={namaRef}
            required
            defaultValue={modal.title}
          />
          <button type="submit" className="btn-cari">
            Go!
          </button>
        </form>
      </div>
    </div>
  );
}

export default ModalEdit;
