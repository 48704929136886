import { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { addDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgress } from "@mui/material";

import "./monitoring.css";
import useStore from "../../store";
import useStoreListUser from "../../store/listUser";
import AuthService from "../../services/auth.services";
import CardCalonMember from "../../components/CardCalonMember/CardCalonMember";
import Modal from "../../components/ModalAddProject/Modal";
import instance from "../../services/axios";
import ModalListUser from "../../components/Modal/ModalListUser/ModalListUser";
import DateLocale from "../../helpers/dateLocale";

function Monitoring({ isLoading, setIsLoading }) {
  const currentUser = AuthService.getCurrentUser();
  const [dataMonitoring, setDataMonitoring] = useState([]);
  const [medsos, setMedsos] = useState("twitter");
  const [, setProgress] = useState(0);

  const getDefaultStartDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getDefaultEndDate = () => {
    const date = new Date();
    date.setHours(23, 58, 1, 0);
    return date;
  };
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getDefaultEndDate());

  const setModal = useStore((state) => state.setIsModal);
  const modal = useStore((state) => state.isModal);

  const setModalListUser = useStoreListUser(
    (state) => state.setIsModalListUser
  );
  const modalListUser = useStoreListUser((state) => state.isModalListUser);

  const startDatePlus = startDate && DateLocale(startDate, 7).toISOString();
  const endDatePlus = endDate && DateLocale(endDate, 7).toISOString();

  const hariIni = new Date();
  const newTahun = hariIni.getFullYear();
  const newBulan = hariIni.getMonth() + 1;
  const newTanggal = hariIni.getDate();
  const newJam = hariIni.getHours();
  const newMenit = hariIni.getMinutes();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataMonitoring = await instance.post(
          "api/usermonitor/data",
          {
            start_date:
              startDatePlus ||
              `${newTahun}-${newBulan}-${newTanggal}T00:00:00Z`,
            end_date:
              endDatePlus ||
              `${newTahun}-${newBulan}-${newTanggal}T${newJam}:${newMenit}:00Z`,
          },
          {
            headers: { Authorization: `Bearer ${currentUser.success.token}` },
          }
        );
        const dataSort = [...dataMonitoring.data].sort(
          (a, b) => b.profile_id - a.profile_id
        );
        setDataMonitoring(dataSort);
        setIsLoading(false);
      } catch (error) {
        console.error("API Error", error);
      }
    };
    fetchData();
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          fetchData();
          return 0;
        } else {
          return prevProgress + 10;
        }
      });
    }, 1350);
    return () => clearInterval(timer);
  }, [
    currentUser.success.token,
    newTahun,
    newBulan,
    newJam,
    newMenit,
    newTanggal,
    startDatePlus,
    endDatePlus,
    setIsLoading,
  ]);

  if (modal) {
    return <Modal />;
  }

  if (modalListUser) {
    return <ModalListUser />;
  }

  return (
    <div className="container-person">
      {isLoading && (
        <div className="loading-circle">
          <CircularProgress size={100} />
        </div>
      )}
      <div className="navbar-stardate">
        {currentUser.success.role_id && (
          <div className="date-picker">
            <div className="date-picker-input">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="MMMM d, yyyy HH:mm"
                minDate={addDays(new Date(endDate), -180)}
                maxDate={new Date()}
              />
            </div>
            <span>-</span>
            <div className="date-picker-input">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="MMMM d, yyyy HH:mm"
                minDate={addDays(new Date(startDate), 1)}
                maxDate={addDays(new Date(startDate), 180)}
              />
            </div>
          </div>
        )}
        <div className="action-monitoring">
          {currentUser.success.role_id !== 5 &&
            currentUser.success.role_id !== 28 &&
            currentUser.success.role_id !== 29 && (
              <div className="icon-modal" onClick={() => setModalListUser()}>
                <PersonAddAltIcon />
              </div>
            )}
        </div>
      </div>
      <div className="wrapper-person">
        {dataMonitoring.length >= 0 ? (
          dataMonitoring.map((data, idx) => (
            <CardCalonMember
              key={idx}
              profilId={data?.profile_id}
              isLoading={isLoading}
              setMedsos={setMedsos}
              medsos={medsos}
              dataCalon={data?.user_data}
              image={`data:image/jpeg;base64, ${data?.profile_link}`}
              nama={data?.profile_title}
              title={data?.profile}
            />
          ))
        ) : (
          <div className="loading-circle">
            <CircularProgress size={100} />
          </div>
        )}
        {((currentUser.success.role_id === 1 && dataMonitoring.length < 5) ||
          (currentUser.success.role_id === 2 && dataMonitoring.length < 10) ||
          (currentUser.success.role_id === 3 && dataMonitoring.length < 20) ||
          (currentUser.success.role_id === 4 &&
            dataMonitoring.length >= 0)) && (
          <div className="add-container" onClick={() => setModal()}>
            <div className="icon-add-container">
              <Add fontSize="large" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Monitoring;
