export const packageData = [
  {
    package_name: "Personal",
    price: "1,2 JT / Bulan",
    history_search: "3 Hari",
    number_of_account: "1 Users",
    topic_setup: "5 Project",
    benefit: [
      {
        value: "Unlimited Keyword",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Real Time Monitoring Tools",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Email Summary",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Dashboard Analytics",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/dont.svg`,
      },
      {
        value: "Customized Email Summary",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/dont.svg`,
      },
      {
        value: "Email Alert for Crisist",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/dont.svg`,
      },
    ],
    bonus: [
      "Webinar",
      "Account Setup",
      "Chat and email support (Response time 6 jam)",
      "Product Training",
      "IT Helpdesk",
    ],
  },
  {
    package_name: "Agency",
    price: "3,5 JT / Bulan",
    history_search: "1 Bulan",
    number_of_account: "3 Users",
    topic_setup: "10 Project",
    benefit: [
      {
        value: "Unlimited Keyword",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Real Time Monitoring Tools",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Email Summary",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Dashboard Analytics",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Customized Email Summary",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/dont.svg`,
      },
      {
        value: "Email Alert for Crisist",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/dont.svg`,
      },
    ],
    bonus: [
      "Webinar",
      "Account Setup",
      "Chat and email support (Response time 6 jam)",
      "Product Training",
      "IT Helpdesk",
    ],
  },
  {
    package_name: "Enterprise",
    price: "CONTACT US",
    history_search: "3 Bulan",
    number_of_account: "5 Users",
    topic_setup: "20 Project",
    benefit: [
      {
        value: "Unlimited Keyword",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Real Time Monitoring Tools",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Email Summary",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Dashboard Analytics",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Customized Email Summary",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
      {
        value: "Email Alert for Crisist",
        icon: `${process.env.REACT_APP_BUCKET_URL}/suar/do.svg`,
      },
    ],
    bonus: [
      "Webinar",
      "Account Setup",
      "Chat and email support (Response time 6 jam)",
      "Product Training",
      "IT Helpdesk",
    ],
  },
];

export const dataLogo = {
  logo: `${process.env.REACT_APP_BUCKET_URL}/suar/suar.svg`,
};
