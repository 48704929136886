import React, { useRef, useState } from "react";
import swal from "sweetalert";
import {
  ArticleOutlined,
  FacebookRounded,
  Instagram,
  Twitter,
  Remove,
  Edit,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import AuthService from "../../services/auth.services";
import useStore from "../../store";
import ModalEdit from "../Modal/ModalEdit";
import instance from "../../services/axios";
import styles from "./CardCalonMember.module.css";

function CardCalonMember({
  isLoading,
  setMedsos,
  medsos,
  dataCalon,
  image,
  nama,
  title,
  profilId,
}) {
  const currentUser = AuthService.getCurrentUser();
  const fileInputRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const modal = useStore((state) => state.isModalEdit);
  const setModal = useStore((state) => state.setIsModalEdit);

  const isValidFileType = (file) => {
    return file.type === "image/webp" || file.type === "image/png";
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (isValidFileType(file)) {
        setErrorMsg("");
        setIsUploading(true);
        try {
          const formData = new FormData();
          formData.append("image", file);
          const response = await instance.post(
            `/api/v3/usermonitor/image/update/${profilId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${currentUser.success.token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.status === 200) {
            swal({
              text: response?.data?.msg,
              icon: "success",
              timer: 5000,
              buttons: false,
            });
          } else {
            swal({
              text: errorMsg,
              icon: "error",
              timer: 5000,
              buttons: false,
            });
          }
        } catch (error) {
          console.error("Error updating image:", error);
          swal({
            text: errorMsg,
            icon: "error",
            timer: 5000,
            buttons: false,
          });
        } finally {
          setIsUploading(false);
        }
      } else {
        setErrorMsg("Hanya file gambar WebP dan PNG yang diizinkan.");
      }
      e.target.value = null; // Clear the file input
    }
  };

  const hapusCalon = async (e) => {
    e.preventDefault();
    try {
      const willDelete = await swal(`Yakin ${title} mau dihapus?`, {
        dangerMode: true,
        buttons: true,
      });
      if (willDelete) {
        try {
          const response = await instance.post(
            `api/v3/usermonitor/delete/${profilId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${currentUser.success.token}`,
              },
            }
          );
          await swal({
            text: response.data.msg,
            icon: "success",
            buttons: false,
            timer: 5000,
          });
        } catch (error) {
          if (error.response && error.response.status === 401) {
            await swal({
              text: error.response?.data?.message,
              icon: "error",
              buttons: false,
              timer: 5000,
            });
          } else {
            await swal({
              text:
                error.response?.data?.msg ||
                "Terjadi kesalahan saat menghapus data.",
              icon: "error",
              buttons: false,
              timer: 5000,
            });
          }
        }
      }
    } catch (error) {
      await swal({
        text: "Terjadi kesalahan. Silakan coba lagi.",
        icon: "error",
        buttons: false,
        timer: 5000,
      });
    }
  };

  // const hapusCalon = (e) => {
  //   e.preventDefault();

  //   swal(`Yakin ${title} mau dihapus?`, {
  //     dangerMode: true,
  //     buttons: true,
  //   })
  //     .then((result) => {
  //       if (result) {
  //         instance
  //           .post(`api/v3/usermonitor/delete/${profilId}`, {
  //             headers: {
  //               Authorization: `Bearer ${currentUser.success.token}`,
  //             },
  //           })
  //           .then((resp) => {
  //             swal({
  //               text: resp.data.msg,
  //               icon: "success",
  //               buttons: false,
  //               timer: 5000,
  //             });
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       swal({
  //         text: error,
  //         icon: "error",
  //         timer: 5000,
  //         buttons: false,
  //       });
  //     });
  // };

  if (modal) {
    return <ModalEdit />;
  }

  return (
    <div className={styles.card_person}>
      <div className={styles.header_person_container}>
        <div className={styles.title_person}>
          <div>
            <button
              className={styles.image_button}
              onClick={handleImageClick}
              type="button"
              disabled={isUploading}
            >
              <img
                src={image}
                alt={nama}
                style={{ width: "100%", height: "100%" }}
              />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              accept="image/webp,image/png"
              style={{ display: "none" }}
            />
          </div>
          {currentUser.success.role_id !== 5 && (
            <>
              <div
                className={styles.icon_edit}
                title="Edit?"
                onClick={() => setModal({ title, nama, image, profilId })}
              >
                <Edit fontSize="normal" />
              </div>
              <div
                className={styles.icon_remove}
                title="Hapus?"
                onClick={hapusCalon}
              >
                <Remove />
              </div>
            </>
          )}
        </div>
        <div className={styles.wrapper_name}>
          <h4>{nama}</h4>
        </div>
      </div>
      <div className={styles.contents_person}>
        <div className={styles.social_media}>
          {dataCalon[3] && (
            <div
              className={
                medsos === "article" ? "icon-sosial active" : "icon-sosial"
              }
              onClick={() => setMedsos("article")}
            >
              <ArticleOutlined fontSize={"medium"} />
            </div>
          )}
          {dataCalon[2] && (
            <div
              className={medsos === "fb" ? "icon-sosial active" : "icon-sosial"}
              onClick={() => setMedsos("fb")}
            >
              <FacebookRounded fontSize={"medium"} />
            </div>
          )}
          {dataCalon[0] && (
            <div
              className={
                medsos === "twitter" ? "icon-sosial active" : "icon-sosial"
              }
              onClick={() => setMedsos("twitter")}
            >
              <Twitter fontSize={"medium"} />
            </div>
          )}
          {dataCalon[1] && (
            <div
              className={medsos === "ig" ? "icon-sosial active" : "icon-sosial"}
              onClick={() => setMedsos("ig")}
            >
              <Instagram fontSize={"medium"} />
            </div>
          )}
        </div>
        <div className={styles.numfound_wrapper}>
          {dataCalon[3] && (
            <div className={styles.numfound}>{dataCalon[3].numFound}</div>
          )}
          {dataCalon[2] && (
            <div className={styles.numfound}>{dataCalon[2].numFound}</div>
          )}
          {dataCalon[0] && (
            <div className={styles.numfound}>{dataCalon[0].numFound}</div>
          )}
          {dataCalon[1] && (
            <div className={styles.numfound}>{dataCalon[1].numFound}</div>
          )}
        </div>
        <div className={styles.content_article}>
          {isLoading && (
            <div className={styles.isLoading}>
              <CircularProgress size={80} />
            </div>
          )}

          {dataCalon[3] &&
            medsos === "article" &&
            dataCalon[3].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
          {dataCalon[2] &&
            medsos === "fb" &&
            dataCalon[2].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
          {dataCalon[1] &&
            medsos === "ig" &&
            dataCalon[1].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
          {dataCalon[0] &&
            medsos === "twitter" &&
            dataCalon[0].data.map((item, idx) => (
              <a
                href={item.link_content}
                target="_blank"
                rel="noreferrer"
                key={idx}
              >
                <div className={styles.article}>{item.content}</div>
              </a>
            ))}
        </div>
      </div>
    </div>
  );
}

export default CardCalonMember;
